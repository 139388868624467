const replaceTitleWithTranslations = (obj, langCode) => {
  if ('translations' in obj && langCode in obj.translations) {
    return { ...obj, title: obj.translations[langCode] };
  }
  return { ...obj };
};

export const formatMailOptions = (data, langCode) => {
  if (data && data.mail) {
    const mailTemplates = data.mail;
    const formatedMailTemplates = mailTemplates.reduce((arr, element) => {
      if (element) return [...arr, { ...replaceTitleWithTranslations(element, langCode) }];
      return [...arr];
    }, []);
    return formatedMailTemplates;
  }
  return [];
};

export const getDefaultMailTemplate = (mailTemplates, langCode) => {
  if (mailTemplates && mailTemplates.length) {
    const defaultElement = mailTemplates.find(
      (element) => element && langCode.includes(element.lang),
    );
    const defaultMailId = defaultElement ? defaultElement.id : mailTemplates[0].id;
    return defaultMailId;
  }
  return '';
};

// Translates signPageOptions to current language
export const formatSignPageOptions = (data, langCode, t, requireSigningPage = false) => {
  if (data && data.signPage) {
    const emptySigningPage = {
      title: t('No signing page'),
      id: 'No signing page',
    };
    const signPages = data.signPage;
    const formatedCases = signPages.reduce((arr, element) => {
      if (element) return [...arr, { ...replaceTitleWithTranslations(element, langCode) }];
      return [...arr];
    }, []);
    if (requireSigningPage) {
      return formatedCases;
    }
    return [...formatedCases, emptySigningPage];
  }
  return [];
};

export const getDefaultSignPage = (signPages, langCode) => {
  if (signPages && signPages.length) {
    const defaultElement = signPages.find((element) => element && langCode.includes(element.lang));
    const defaultId = defaultElement ? defaultElement.id : signPages[0].id;
    return defaultId;
  }
  return '';
};

// Function to process signers data
const processSignersData = (signersData, signIdps, originalSignersData) =>
  signersData.map((signer) => {
    const originalSigner = originalSignersData?.find(
      (s) => s.ID.toString() === signer.id.toString(),
    );
    const signerName = signer.name ? signer.name : '';
    return {
      ...signer,
      email: originalSigner?.Signed ? originalSigner?.Email[0] : signer?.email,
      id: signer.id ? parseInt(signer.id, 10) : undefined,
      identityProviders: signIdps.filter((idp) => signer.idpsForSigner[idp.id]),
      personalID: signer.personalID ? signer.personalID.replace('-', '') : '',
      name: originalSigner?.Signed ? originalSigner?.Name : signerName,
    };
  });

export const checkSignerHasIdp = ({ t, signIdps, setError, name, signerState }) => {
  const idpPresent = signIdps.some((idp) => signerState.idpsForSigner[idp.id]);
  if (idpPresent) return true;
  setError(`${name}.idp`, { type: 'manual', message: t('At least one method required') });
  return false;
};

const allSignersHaveIdp = ({ signersData, signIdps, setError, t }) =>
  signersData.every((signerState, index) =>
    checkSignerHasIdp({ name: `signers[${index}]`, signerState, signIdps, setError, t }),
  );

export const processSignRequestData = async ({
  signIdps,
  originalSignersData,
  data,
  setError,
  completedSignRequestRecipients,
  t,
}) => {
  const { signers: signersData, date } = data;
  const signersOk = allSignersHaveIdp({ signersData, signIdps, setError, t });
  if (!signersOk) {
    window.scrollTo(0, 0);
    return undefined;
  }
  return {
    ...data,
    date: new Date(date === '' ? '0001-01-01' : `${date}T23:59:59`),
    signers: processSignersData(signersData, signIdps, originalSignersData),
    completedSignRequestRecipients,
  };
};
export const hasRepeatedEmail = (signersData, setRepeatedEmailErrors, t) => {
  const errorMsg = t('same email twice');
  // Check for duplicates and update the duplicates object
  const { duplicates } = signersData?.reduce(
    (acc, signer, idx) => {
      if (signer.email) {
        const email = signer.email.replace(/ /g, '').trim();
        if (email in acc.seenEmails && acc.seenEmails[email] !== idx) {
          acc.duplicates[idx] = errorMsg;
          acc.duplicates[acc.seenEmails[email]] = errorMsg;
        } else {
          acc.seenEmails[email] = idx;
        }
      }
      return acc;
    },
    { duplicates: {}, seenEmails: {} },
  ) ?? { duplicates: {} };

  setRepeatedEmailErrors(duplicates); // TODO: Consider changing the state function name as well
  return Object.keys(duplicates).length > 0 ? duplicates : null;
};
