import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';
import { DrawerContext } from '@store/appContext';

export const ListItemLink = ({ icon, primary, to }) => {
  const { t } = useTranslation();
  const { setDrawer } = useContext(DrawerContext);
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} onClick={() => setDrawer(false)} />
      )),
    [to, setDrawer],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={t(primary)} />
      </ListItem>
    </li>
  );
};
