import React, { useState } from 'react';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { langNames } from '@i18n/lang';

const useStyles = makeStyles((theme) => ({
  flag: {
    height: '1.5em',
    marginRight: theme.spacing(1),
  },
  selectLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    padding: theme.spacing(1.5, 2),
    width: '10em',
    [theme.breakpoints.down('xs')]: { width: '8em' },
  },
}));

export const LangMenuSelect = () => {
  const { i18n } = useTranslation();
  const languages = Object.keys(i18n.store.data);
  const classes = useStyles();

  const [selectedLang, setSelectedLang] = useState(i18n.language.split('-')[0]);

  const changeLang = (lang) => () => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };

  return (
    <>
      <Select
        labelId="lang"
        variant="outlined"
        value={selectedLang}
        classes={{ root: classes.select }}
      >
        {languages.map((lang) => (
          <MenuItem key={lang} value={lang} onClick={changeLang(lang)}>
            <span className={classes.selectLabel}>
              <img src={`/flags/${lang}.svg`} alt={`${lang}-flag`} className={classes.flag} />
              {langNames[lang]}
            </span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
