import * as R from 'ramda';
import { isBefore, format } from 'date-fns/fp';

import { getEmail, getPersonalID } from './jwt';

const defaultDate = new Date('0001-01-01T00:00:00Z');

export const reduceIndexed = R.addIndex(R.reduce);
export const includedIn = R.flip(R.includes);

export const anyFoundIn = (list1) => R.any(includedIn(list1));

const knownDateKeys = ['CreatedAt', 'UpdatedAt', 'DeletedAt', 'ExpirationDate'];
const isDateKey = R.includes(R.__, knownDateKeys);

const fullTimeFormatter = format('yyyy-MM-dd HH:mm.ss');

const getFormatter = () => fullTimeFormatter;

const timestampEvolve = (timeKey, timestamp) => (obj) => {
  const dateObj = new Date(timestamp);
  return {
    ...obj,
    [timeKey]: dateObj,
    [`formatted${timeKey}`]: dateObj.getFullYear() < 1000 ? '' : getFormatter(timeKey)(dateObj),
  };
};

/**
 * Reducer for json-reduce that converts timestamp fields.
 * When a timestamp key is found, 2 things happen:
 * - Value is converted to a Date object.
 * - An additional field (key prefixed with "formatted") is added with the date formatted.
 * @param {(object|array)} data Any reducable structure.
 * @returns {(object|array)} Same structure, but timestamps converted.
 */
export const timestampReducer = (acc, val, path) => {
  const key = R.last(path);
  if (key === undefined) return acc; // root level
  const parentPath = R.init(path);
  // console.log('REDUCE:', key, parentPath);
  return isDateKey(key) && val
    ? R.over(R.lensPath(parentPath), timestampEvolve(key, val))(acc)
    : R.assocPath(path, val)(acc);
};

export const findSignerThatIsMe = ({ Signers = [] }) => {
  const index = R.findIndex(
    R.allPass([
      R.propSatisfies(anyFoundIn(getEmail()), 'Email'),
      R.propSatisfies((personalID) => !personalID || personalID === getPersonalID(), 'PersonalID'),
    ]),
  )(Signers);
  const signer = index > -1 ? R.prop(index)(Signers) : undefined;
  return [signer, index]; // [undefined, -1] in case I don't exist in Signers
};

export const isBlockedForMeCheck = ({ Signers = [], indexOfMe, ForceSigningOrder }) =>
  ForceSigningOrder && indexOfMe > 0 && !Signers[indexOfMe - 1]?.Signed;

export const canSignCheck = ({ Revoked, status, signerThatIsMe, isBlockedForMe, indexOfMe }) =>
  !Revoked && status !== 'Expired' && indexOfMe > -1 && !signerThatIsMe?.Signed && !isBlockedForMe;

export const canRevokeCheck = ({ Revoked, status, signerThatIsMe, ownerIsMe }) =>
  status !== 'Expired' && !Revoked && (!signerThatIsMe?.Signed || ownerIsMe);

export const caseSigningStatus = (signCase = {}) => {
  const { ExpirationDate = defaultDate, Revoked = false, Signers = [] } = signCase;
  const signStatuses = R.pluck('Signed')(Signers);
  const rejectStatuses = R.pluck('Rejected')(Signers);
  const readStatuses = R.pluck('Read')(Signers);
  // Check if the sign request has been revoked - signed requests can't be revoked/revoked requests can't be signed
  if (Revoked && !rejectStatuses.includes(true)) return 'Revoked';
  // Check if the sign request has been rejected
  if (rejectStatuses.includes(true)) return 'Rejected';
  // Check if all the signers have signed
  if (signStatuses.every(R.identity)) return 'Signed';
  // Check if it has passed the expiration date. TODO: Rewrite below with date-fns
  if (ExpirationDate.getFullYear() > 1000 && isBefore(Date.now(), ExpirationDate)) return 'Expired';
  // An active request can either be partially signed
  if (signStatuses.includes(true)) return 'Partially signed';
  // document have been read by all signers
  if (readStatuses.every(R.identity)) return 'Read';
  // Or it has not been signed by any of the parties yet
  if (signStatuses.every(R.not)) return 'Sent';
  return 'Unknown';
};

const accumulateMeSigners = ({ myIndex, Signers }, signer, ix) => {
  const isMe = R.propSatisfies(includedIn(getEmail()), 'Email')(signer);
  const previousMe = myIndex !== -1;
  return isMe && previousMe
    ? {
        myIndex, // keep first index of me
        Signers: R.adjust(
          myIndex, // evolve first occurence of me in Signers
          R.evolve({
            Email: R.append(signer.Email),
            Signed: R.or(signer.Signed),
            // IdentityProviders: R.reduce(idp => , signer.IdentityProviders),
            IdentityProviders: R.pipe(
              R.concat(signer.IdentityProviders),
              R.uniqBy(R.prop('displayName')),
            ),
          }),
        )(Signers),
      }
    : // otherwise track if this was me, and make sure Email is array
      {
        myIndex: isMe ? ix : myIndex,
        Signers: R.append(R.evolve({ Email: R.of })(signer))(Signers),
      };
};

export const signersReducer = R.pipe(
  reduceIndexed(accumulateMeSigners, { myIndex: -1, Signers: [] }),
  R.prop('Signers'),
);
