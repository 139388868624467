import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { CasesStyledEmails } from '@views/cases/components/CasesStyledEmails';

export const TableDocCell = ({ name = '-', date = '-', Signers = [] }) => {
  const { t } = useTranslation();

  const textWrapStyle = {
    wordBreak: 'break-word',
    hyphens: 'auto',
  };

  return (
    <div>
      <Typography gutterBottom style={textWrapStyle}>
        {name}
      </Typography>
      <Typography variant="caption">{t('Created')}:</Typography>
      <Typography gutterBottom>{date}</Typography>
      <Typography variant="caption">{t('Signers')}:</Typography>
      <div>
        <CasesStyledEmails showItalic Signers={Signers} compact />
      </div>
    </div>
  );
};
