import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { maintenanceDate } from '@utils/maintenanceDate';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(2) },
  },
  title: {
    textAlign: 'center',
    marginTop: '0px',
  },
}));

export const ServiceUnavailablePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const params = useParams();
  const message = params && params.message ? params.message : null;

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        {message ??
          t('SignPort for Knowit is temporary unavailable for scheduled maintenance', {
            maintenanceDate,
          })}
      </h2>
    </div>
  );
};
