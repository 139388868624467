import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  logo: {
    height: 'auto',
    width: ({ size }) => size,
    padding: ({ padding }) => padding,
  },
}));

export const MainLogo = ({ size = 128, className }) => {
  const theme = useTheme();
  const logoUrl = theme.logo.content;
  const logoSize = theme.mainLogoWidth ?? size;
  const logoPadding = theme.mainLogoPadding ?? '';
  const { i18n } = useTranslation();
  const classes = useStyles({ size: logoSize, padding: logoPadding });

  return (
    <img
      src={`/${logoUrl}`}
      className={clsx([classes.logo, className])}
      // Uninitialized language will be 'en-GB' but we expect 'en'. Default to 'sv' if null.
      alt={theme.logoAlt[i18n.language === 'en-GB' ? 'en' : i18n.language ?? 'sv']}
    />
  );
};
