import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { grey } from '@material-ui/core/colors';

import { statusIcons } from '@utils/icons';
import { ResponsiveUrlOrEmail } from '@components/ResponsiveUrlOrEmail';

const useStyles = makeStyles(() => ({
  emailContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '4px 0',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  borderBottom: {
    borderBottom: '1px solid lightgrey',
  },
}));

const formatPersonalNumber = (string) => {
  if (string && string.length === 12) {
    // Long format
    const returnStr = `${string.slice(0, 8)}-${string.slice(8)}`;
    return returnStr;
  }
  if (string && string.length === 10) {
    // Short format
    const returnStr = `${string.slice(0, 6)}-${string.slice(6)}`;
    return returnStr;
  }
  return '';
};

const renderSignerStatus = (signed, read, rejected) => {
  if (signed) return <statusIcons.Signed fontSize="small" />;
  if (rejected) return <statusIcons.Rejected fontSize="small" />;
  if (read) return <statusIcons.Read fontSize="small" />;
  return <statusIcons.Sent fontSize="small" />;
};

export const CasesStyledEmails = ({ Signers = [], compact = false }) => {
  const classes = useStyles();
  const [renderSigners, setRenderSigners] = useState([]);

  useEffect(() => {
    if (compact) {
      if (Signers.length > 2) {
        const compactArray = Signers.slice(0, 1);
        compactArray.push({
          hidden: ` (+${Signers.length - 1})`,
        });
        setRenderSigners(compactArray);
      } else {
        setRenderSigners(Signers);
      }
    } else {
      setRenderSigners(Signers);
    }
  }, [Signers]);

  return renderSigners.map(
    ({ Signed, Email = [], Read, Rejected, PersonalID, hidden, Name }, index) => (
      <Fragment key={Email}>
        <div
          className={clsx([
            classes.emailContainer,
            index < renderSigners.length - 1 ? classes.borderBottom : {},
          ])}
        >
          {hidden ? (
            <>
              <PersonIcon style={{ color: grey[500] }} />
              <div>
                <ResponsiveUrlOrEmail str={hidden} />
              </div>
            </>
          ) : (
            <>
              {renderSignerStatus(Signed, Read, Rejected)}
              <div>
                <ResponsiveUrlOrEmail str={Email.join(' / ')} />
                {Name && (
                  <>
                    <br />
                    {Name}
                  </>
                )}
                {PersonalID && (
                  <>
                    <br />
                    {formatPersonalNumber(PersonalID)}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Fragment>
    ),
  );
};
