import React, { lazy, useContext, Suspense } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import { LoginPage } from '@views/login/LoginPage';
import { RefreshHandler } from '@frontend/RefreshHandler';
import { AppMachineContext } from '@store/appMachine';

export const AuthGuard = () => {
  const [{ matches }] = useContext(AppMachineContext);

  const authenticated = matches('authenticated');
  const loading = matches('checking');

  const location = useLocation();

  const ValidateSignedOutPage = lazy(() => import('@lazy-loading/LazyValidateSignedOutPage'));

  const theme = useTheme();

  const { features } = theme;

  const disablePortal = features && 'disablePortal' in features ? features.disablePortal : false;

  const publicValidation =
    features && 'publicValidation' in features ? features.publicValidation : false;

  if (authenticated) return <RefreshHandler />;
  if (loading)
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );
  if (disablePortal || (publicValidation && location.pathname === '/validate'))
    return (
      <Suspense fallback={<CircularProgress />}>
        <ValidateSignedOutPage />
      </Suspense>
    );
  return <LoginPage />;
};
