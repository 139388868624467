import React, { useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { drawerBreakpoint } from '@root/app.config';
import { DelegateAuthority } from '@views/settings/components/DelegateAuthority';
import { PageSettings } from '@views/settings/components/PageSettings';
import { isSender } from '@utils/jwt';
import { canSeeEmailSettings, EmailSettings } from '@views/settings/components/EmailSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up(drawerBreakpoint)]: { padding: theme.spacing(3) },
  },
  headline: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h5.fontSize,
      padding: theme.spacing(2, 0, 3, 0),
    },
    padding: theme.spacing(3, 0, 2, 0),
  },
  accordionHeader: {
    marginLeft: theme.spacing(1),
  },
  dialog: {
    flex: '1 1 auto',
  },
  box: { marginBottom: theme.spacing(3) },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  helpText: {
    padding: theme.spacing(3),
  },
  reverseAccordionSummary: {
    flexDirection: 'row-reverse',
    paddingLeft: 0,
  },
  accordionContent: {
    margin: '20px 0',
  },
  help: {
    [theme.breakpoints.up(drawerBreakpoint)]: { maxWidth: '75%' },
    width: '100%',
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  expanded: {},
  AccordionDetails: {
    [theme.breakpoints.down('xs')]: { padding: theme.spacing(0) },
  },
}));

const settingItems = [
  {
    title: 'Page Settings',
    name: 'general',
    render: <PageSettings />,
    helpTitle: '',
    helpText: '',
  },
  {
    title: 'Delegate authority as administrator',
    name: 'delegate',
    render: <DelegateAuthority />,
    helpTitle: 'delegateHelpTitle',
    helpText: 'delegateHelp',
  },
  {
    title: 'Manage my emails',
    name: 'email',
    render: <EmailSettings />,
    helpTitle: 'manageEmailsHelpTitle',
    helpText: 'manageEmailsHelp',
  },
];

export const SettingsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { page } = useParams();
  const { features } = useTheme();
  const delegateAuthority =
    features && 'delegateAuthority' in features ? features.delegateAuthority : null;
  const [anchorEl, setAnchorEl] = useState(null);
  const helpData = useRef({ title: '', text: '' });

  const closePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = (e, helpTitle, helpText) => {
    e.stopPropagation();
    helpData.current = { title: helpTitle, text: helpText };
    setAnchorEl(e.currentTarget);
  };
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const authorizedSetting = (settingName) => {
    const restrictedSettings = ['delegate'];
    if (settingName === 'delegate' && !delegateAuthority) return false;
    if (restrictedSettings.includes(settingName)) return isSender();

    if (settingName === 'email' && !canSeeEmailSettings()) return false;

    return true;
  };

  const authorizedSettings = settingItems.reduce(
    (arr, item) => (authorizedSetting(item.name) ? [...arr, item] : [...arr]),
    [],
  );

  const [accordion, setAccordion] = useState(
    page && authorizedSettings.find((e) => e.name === page) ? page : 'general',
  );

  const accordionChange = (panel) => (_, isExpanded) => {
    setAccordion(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.help}
      >
        <Box className={classes.helpText}>
          <Typography variant="h5">{t(helpData.current.title)}</Typography>
          <Typography>{t(helpData.current.text)}</Typography>
        </Box>
      </Popover>
      {authorizedSettings.map((item) => (
        <Accordion
          expanded={accordion === item.name}
          onChange={accordionChange(item.name)}
          key={`accordion-item-${item.name}`}
        >
          <AccordionSummary
            className={classes.reverseAccordionSummary}
            classes={{
              expandIcon: classes.expandIcon,
              expanded: classes.expanded,
              content: classes.accordionContent,
            }}
            expandIcon={<ChevronRight />}
            aria-controls={`${item.name}-content`}
            id={`${item.name}-header`}
          >
            <Typography variant="h5" className={classes.accordionHeader}>
              {t(item.title)}
            </Typography>
            {item.helpText.length ? (
              <Button onClick={(e) => openPopover(e, item.helpTitle, item.helpText)}>
                <HelpOutline />
              </Button>
            ) : null}
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>{item.render}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
