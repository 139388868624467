import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { IdleHandler } from '@frontend/IdleHandler';
import { AppMachineContext } from '@store/appMachine';
import { isExpired } from '@utils/jwt';
import { write, remove, sessionRemove } from '@utils/storage';
import { refreshToken } from '@store/authAPI';

export const RefreshHandler = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  // console.log({ pathname });
  // const history = useHistory();
  const [, send] = useContext(AppMachineContext);

  useEffect(() => {
    // Login in should clear this flag
    remove('logOut');

    // Cross-tab communication using local storage.
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'logOut' && event.newValue === 'true') {
        history.push('/');
        sessionRemove('toURL');
        send('LOG_OUT');
      }
    });
    // Async background check if token has expired, refreshes or log out on fail:
    const expired = isExpired();
    if (expired)
      refreshToken().catch((error) => {
        history.push('/');
        write('logOut', 'true');
        send('LOG_OUT', { data: error.message });
      });
    return () => {
      window.removeEventListener('storage', null);
    };
  }, [pathname, send]);

  return <IdleHandler />;
};
