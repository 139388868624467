import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getEmailVerificationIdpEntityId, postLogin, refreshToken } from '@store/authAPI';
import { getEmail, isSender, userHasEmail } from '@utils/jwt';
import { getLocalizations } from '@views/cases/components/CasesTableConfig';
import { tableIcons } from '@utils/icons';
import { removeUserEmail } from '@store/appAPI';
import { sessionRemove, write } from '@utils/storage';
import { AppMachineContext } from '@store/appMachine';
import { WarningMessage } from '@components/WarningMessage';

export const canSeeEmailSettings = () => !isSender();

const getTableConfig = (theme, t, onDelete) => {
  const columns = [{ title: t('Email'), field: 'email' }];
  const options = {
    actionsColumnIndex: -1,
    cellStyle: {
      fontSize: '0.9rem',
    },
    headerStyle: {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.action.disabledBackground,
    },
    searchFieldStyle: {
      fontSize: '0.9rem',
    },
    pageSize: 5,
    emptyRowsWhenPaging: false,
    thirdSortClick: false,
  };
  const actions = [
    {
      icon: DeleteOutline,
      tooltip: t('Remove Email'),
      onClick: (event, rowData) => onDelete(rowData),
    },
  ];
  return { columns, options, actions };
};

export const EmailSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const localization = useMemo(() => getLocalizations(t), [t]);
  const getRowData = () => getEmail().map((e) => ({ email: e }));
  const [rowData, setRowData] = useState(getRowData());
  const history = useHistory();

  const [dialog, setDialog] = useState(null);
  const openDialog = (data) => setDialog(data);
  const closeDialog = () => setDialog(null);
  const [, send] = useContext(AppMachineContext);

  const logout = () => {
    write('logOut', 'true');
    sessionRemove('toURL');
    send('LOG_OUT');
  };

  const removeEmail = async (email) => {
    await removeUserEmail(email).then(async () => {
      refreshToken()
        .then(async () => {
          if (userHasEmail()) {
            history.push('/settings');
            setRowData(getRowData());
          } else logout();
        })
        .catch((error) => {
          history.push('/');
          write('logOut', 'true');
          send('LOG_OUT', { data: error.message });
        });
    });
  };
  const showWarning = () => getEmail().length === 1 && dialog && dialog.email === getEmail()[0];
  const onConfirm = async () => {
    await removeEmail(dialog.email);
    closeDialog();
  };

  const addNewEmail = async () => {
    const entityId = await getEmailVerificationIdpEntityId();
    if (entityId) postLogin(entityId['email-verification-idp-entity-id']);
  };

  const { columns, options, actions } = getTableConfig(theme, t, openDialog);

  return (
    <div style={{ width: '100%' }}>
      <Dialog
        open={!!dialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('Remove Email')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('The following email address will no longer be associated with this account')}:
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {dialog && dialog.email}
          </DialogContentText>
          {showWarning() && (
            <WarningMessage
              message={t(
                'If you remove your last email address, you will be logged out of the app',
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} color="secondary" autoFocus variant="contained">
            {t('Remove Email')}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="subtitle1">{t('Connect a new email address to my account')}</Typography>
      <Divider />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={addNewEmail}
        style={{ marginBottom: 25, marginTop: 25 }}
      >
        {t('Add Email')}
      </Button>
      <MaterialTable
        title={<Typography variant="subtitle1">{t('Connected Emails')}</Typography>}
        icons={tableIcons}
        columns={columns}
        data={rowData}
        actions={actions}
        options={options}
        localization={localization}
      />
    </div>
  );
};
