import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { WarningMessage } from '@components/WarningMessage';

export const LoginError = () => {
  const history = useHistory();
  const params = useParams();
  const [error] = useState({ message: params.errorMsg, show: params.showError });
  useEffect(() => {
    // clear url
    history.push(`/`);
  }, []);

  return (
    <>
      {error.show === 'showError' && error.message && (
        <Alert severity="error">
          <WarningMessage message={error.message.toLowerCase()} namespace="errors" />
        </Alert>
      )}
    </>
  );
};
