import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { Layout } from '@layout/Layout';
import { ErrorPage } from '@views/error/ErrorPage';
import { SettingsPage } from '@views/settings/SettingsPage';
import { ChangeCasePage } from '@views/cases/ChangeCasePage';
import { isSender } from '@utils/jwt';
import { ServiceUnavailablePage } from '@views/service-unavailable/ServiceUnavailablePage';
// import { NotFoundPage } from './NotFoundPage';

const SignPage = lazy(() => import('@lazy-loading/LazySignPage'));
const CasesPage = lazy(() => import('@lazy-loading/LazyCasesPage'));
const CaseViewPage = lazy(() => import('@lazy-loading/LazyCaseViewPage'));
const CreateCase = lazy(() => import('@lazy-loading/LazyCreateCase'));
const QuickSign = lazy(() => import('@lazy-loading/LazyQuickSign'));
const ValidatePage = lazy(() => import('@lazy-loading/LazyValidatePage'));

export const AppRouter = () => {
  const { features } = useTheme();
  const quickSign = isSender() && features && 'quickSign' in features ? features.quickSign : null;
  return (
    <Layout>
      <Switch>
        <Route path="/exception/:errorMsg?/:errorCode?" exact>
          <Suspense fallback={<CircularProgress />}>
            <ErrorPage />
          </Suspense>
        </Route>
        <Route path="/serviceUnavailable/:message?" exact>
          <Suspense fallback={<CircularProgress />}>
            <ServiceUnavailablePage />
          </Suspense>
        </Route>
        <Route path="/cases/:id/:message?">
          <Suspense fallback={<CircularProgress />}>
            <CaseViewPage />
          </Suspense>
        </Route>
        {isSender() && (
          <Route path="/change/:id/" exact>
            <Suspense fallback={<CircularProgress />}>
              <ChangeCasePage />
            </Suspense>
          </Route>
        )}
        {isSender() && (
          <Route path="/create" exact>
            <Suspense fallback={<CircularProgress />}>
              <CreateCase />
            </Suspense>
          </Route>
        )}
        <Route path="/sign/:id" exact>
          <Suspense fallback={<CircularProgress />}>
            <SignPage />
          </Suspense>
        </Route>

        {quickSign && (
          <Route path="/quicksign" exact>
            <Suspense fallback={<CircularProgress />}>
              <QuickSign />
            </Suspense>
          </Route>
        )}
        <Route path="/validate" exact>
          <Suspense fallback={<CircularProgress />}>
            <ValidatePage />
          </Suspense>
        </Route>
        <Route path={['/settings/:page', '/settings']} exact>
          <Suspense fallback={<CircularProgress />}>
            <SettingsPage />
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense fallback={<CircularProgress />}>
            <CasesPage />
          </Suspense>
        </Route>
        {
          //   <Route>
          //   <NotFoundPage />
          // </Route>
        }
      </Switch>
    </Layout>
  );
};
