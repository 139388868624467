import React, { useContext } from 'react';
import { isBefore } from 'date-fns/fp';
import * as R from 'ramda';
import { withStyles } from '@material-ui/core';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import MultiFile from '@material-ui/icons/FileCopy';
import NumbersIcon from '@mui/icons-material/Numbers';

import { TableDocCell } from '@views/cases/components/TableDocCell';
import { CasesStyledEmails } from '@views/cases/components/CasesStyledEmails';
import { getStatusIcon } from '@utils/icons';
import { DarkContext } from '@store/appContext';

const compareByFirstFileName = R.comparator(
  ({ fileList: [a = ''] = [] }, { fileList: [b = ''] = [] }) => a.toUpperCase() < b.toUpperCase(),
);
const compareByFirstEmail = R.comparator(
  ({ signersEmailsAccumulated: [a = ''] = [] }, { signersEmailsAccumulated: [b = ''] = [] }) =>
    a.toLowerCase() < b.toLowerCase(),
);
const compareByCreatedAt = R.comparator(({ CreatedAt: a }, { CreatedAt: b }) => isBefore(b, a));

export const Status = ({ status, formattedStatus }) => {
  const Icon = getStatusIcon(status);
  return (
    <>
      <Icon style={{ marginRight: 10 }} />
      {formattedStatus}
    </>
  );
};

const iconStyle = { root: { verticalAlign: 'middle', margin: '0 2px 2px 0' } };
const FileIconAligned = withStyles(iconStyle)(FileIcon);
const MultiFileAligned = withStyles(iconStyle)(MultiFile);

const textWrapStyle = {
  wordBreak: 'break-word',
  hyphens: 'auto',
};

export const ShortenedFiles = ([name, ...files], ID) => {
  const { isDark } = useContext(DarkContext);
  return (
    <>
      <span style={{ display: 'flex', marginBottom: '0.5em' }}>
        <NumbersIcon
          sx={{ color: isDark ? 'rgba(255, 255,255, 0.4)' : 'rgba(0, 0,0, 0.4)' }}
          fontSize="small"
        />{' '}
        {`${ID}`}
      </span>
      <FileIconAligned color="disabled" fontSize="small" />
      <span style={textWrapStyle}>{name}</span>
      {files.length > 0 && (
        <>
          <br />
          {files.length === 1 ? (
            <>
              <FileIconAligned color="disabled" fontSize="small" />
              <span style={textWrapStyle}>{files[0]}</span>
            </>
          ) : (
            <>
              <MultiFileAligned color="disabled" fontSize="small" />
              <span style={textWrapStyle}>{`(+${files.length})`}</span>
            </>
          )}
        </>
      )}
    </>
  );
};

export const tableColumnsNarrow = [
  {
    title: 'Sign requests',
    field: 'files',
    customSort: compareByCreatedAt,
    cellStyle: {
      fontSize: '0.9rem',
      padding: '14px 10px',
      textAlign: 'left',
    },
    render: ({ fileList = [], formattedCreatedAt = '', Signers = [], ID }) => (
      <TableDocCell
        name={ShortenedFiles(fileList, ID)}
        date={formattedCreatedAt.split(' ')[0]}
        Signers={Signers}
      />
    ),
  },
  {
    // hidden column - to make emails searchable in the toolbar searchfield
    title: 'Emails',
    field: 'signersEmailsAccumulated',
    hidden: true,
    searchable: true,
  },
  {
    // hidden column - to fix default sorting
    title: 'Created at',
    field: 'formattedCreatedAt',
    defaultSort: 'desc',
    hidden: true,
    searchable: true,
  },
  {
    title: 'Name',
    field: 'fileList',
    hidden: true,
    searchable: true,
  },
  {
    // hidden column - to make ID searchable in the toolbar searchfield
    title: 'ID',
    field: 'ID',
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'formattedStatus',
    render: Status,
  },
];

export const tableColumnsWide = [
  {
    title: 'Name',
    field: 'fileList',
    customSort: compareByFirstFileName,
    render: ({ fileList = [], ID }) => ShortenedFiles(fileList, ID),
  },
  {
    title: 'Status',
    field: 'formattedStatus',
    render: Status,
  },
  {
    title: 'Created',
    field: 'formattedCreatedAt',
    defaultSort: 'desc',
    render: (row) => row.formattedCreatedAt.split('.')[0],
  },
  {
    title: 'Administrator',
    field: 'owner',
    // render: ({ owner }) => owner,
  },
  {
    title: 'Expiring',
    field: 'formattedExpirationDate',
  },
  {
    title: 'Signers',
    customSort: compareByFirstEmail,
    field: 'Signers',
    render: CasesStyledEmails,
  },
  {
    // hidden column - to make emails searchable in the toolbar searchfield
    title: 'Emails',
    field: 'signersEmailsAccumulated',
    hidden: true,
    searchable: true,
  },
  // hidden column - to make signersName searchable in the toolbar searchfield
  {
    title: 'Names',
    field: 'signersNamesAccumulated',
    hidden: true,
    searchable: true,
  },
  // hidden column - to make personalNumber searchable in the toolbar searchfield
  {
    title: 'PersonalNumber',
    field: 'personalNumberAccumulated',
    hidden: true,
    searchable: true,
  },
  {
    // hidden column - to make ID searchable in the toolbar searchfield
    title: 'ID',
    field: 'ID',
    hidden: true,
    searchable: true,
  },
];

export const getOptionsWide = (theme, initialPage) => ({
  headerStyle: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  pageSize: 10,
  pageSizeOptions: [10, 50, 100],
  emptyRowsWhenPaging: false,
  thirdSortClick: false,
  initialPage,
});

export const getOptionsNarrow = (theme, initialPage) => ({
  cellStyle: {
    fontSize: '0.9rem',
  },
  headerStyle: {
    fontSize: '0.9rem',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  searchFieldStyle: {
    fontSize: '0.9rem',
  },
  filterCellStyle: {
    padding: '16px',
  },
  pageSize: 10,
  pageSizeOptions: [10, 50, 100],
  emptyRowsWhenPaging: false,
  thirdSortClick: false,
  initialPage,
});

export const getLocalizations = (t) => ({
  pagination: {
    labelDisplayedRows: t('labelDisplayedRows', { context: 'MaterialTable' }),
    labelRowsSelect: t('rows'),
    firstAriaLabel: t('First Page'),
    firstTooltip: t('First Page'),
    previousAriaLabel: t('Previous Page'),
    previousTooltip: t('Previous Page'),
    nextAriaLabel: t('Next Page'),
    nextTooltip: t('Next Page'),
    lastAriaLabel: t('Last Page'),
    lastTooltip: t('Last Page'),
  },
  toolbar: {
    searchPlaceholder: t('Search'),
    searchTooltip: t('Search'),
    nRowsSelected: t('nRowsSelected', { context: 'MaterialTable' }),
  },
  header: {
    actions: '',
    // actions: t('Actions'),
  },
  body: {
    deleteTooltip: t('Delete'),
    emptyDataSourceMessage: t('No records to display'),
    filterRow: {
      filterTooltip: t('Filter'),
    },
  },
});
